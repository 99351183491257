import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "integrate-a-nodejs-service"
    }}>{`Integrate a Node.js service`}</h2>
    <p>{`The way you can integrate security checks in your Node.js application is by adding a middleware in the web application framework
of choice, (like express, koa, nest.js, etc.)`}</p>
    <p>{`In any case, you should wrap your token code in a specific Handler, as expressed in the example below,
so that you are able to fetch Ocelot certificate to verify the token signature and extract successfully the claims.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export type JwtHandler = {
  getJwtCertificateWithRetry(): Promise<void>
  verifyJwt(_token: string, _alternativeAudience?: string): Promise<ExpectedClaims>
  getAccessToken(_scope: string, _reqJwt: string): Promise<string>
  context(_req: { req: any }):  Promise<{verifiedJWT: ExpectedClaims}>
}

export default (configurationService: ConfigurationService): JwtHandler => {
  const vault = getVaultInstance(configurationService.getVaultType(), configurationService.getProjectId());
  return {
    getJwtCertificateWithRetry: async () => await getJWTCertificateWithRetry(
      configurationService.getJwtCertServiceHost(),
      configurationService.getJwtCertServicePort(),
      configurationService.getJwtCertRetryDelayMilliseconds(),
      configurationService.getJwtCertErrorRetryDelayMilliseconds(),
      configurationService.getJwtCertErrorRetryDelayMilliseconds(),
      vault),
    verifyJwt: async (token: string, alternativeAudience: string = null) =>
      await verifyJWT(token, configurationService.getJwtIssuer(), alternativeAudience),
    getAccessToken: async (scope: string, reqJwt: string) =>
      await getAccessToken(
        scope,
        reqJwt,
        configurationService.getJwtCertServiceHost(),
        configurationService.getJwtCertServicePort(),
        vault),
    context: async ({ req }: { req: any }) =>
      await context({ req }, configurationService.getJwtIssuer())
  };
};
`}</code></pre>
    <p>{`The complete samples are available on our `}<a parentName="p" {...{
        "href": "https://github.com/YanchWare/ocelot-samples"
      }}>{`github repo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      